
import { Action } from "vuex-class";
import { Component, Ref, Mixins, Prop } from "vue-property-decorator";
import { FormBase, FormSubmit } from "@/components/forms";
import { bus } from "@/main";
import { HasFormErrors } from "@/mixins/has-form-errors";

import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import Placeholder from "@tiptap/extension-placeholder";
import Mention from "@tiptap/extension-mention";
import suggestion from "@/components/mentions/suggestion";
import { PatchTimelinePayload } from "@/store/modules/timeline.store";

@Component({
    components: {
        LayoutModal: () => import("@/layouts/components/modal.vue"),
        EditorContent,
        FormBase,
        FormSubmit,
    },
})
export default class EditTimelineEntryModal extends Mixins(HasFormErrors) {
    @Action("timeline/patch") patchEntry!: (payload: PatchTimelinePayload) => Promise<TimelineItem>;
    @Action("timeline/delete") deleteEntry!: (id: number) => Promise<void>;

    @Ref() form!: FormClass;

    @Prop({
        default: () => {
            return { label: "Notitie", value: "NOTE" };
        },
    })
    defaultType!: any;

    deleting = false;

    deleted = false;

    show = false;

    editor: Editor | null = null;

    entry: TimelineItem | null = null;

    entryType: { label: string; value: "NOTE" | "EMAIL" | "PHONE" | "SELLER_NOTE" } = this.defaultType;

    entryOptions = [
        { label: "Notitie", value: "NOTE" },
        { label: "Email", value: "EMAIL" },
        { label: "Gesprek", value: "PHONE" },
    ];

    mounted() {
        bus.$off("show-edit-timeline-entry");
        bus.$on("show-edit-timeline-entry", (payload: TimelineItem) => {
            this.show = true;
            this.entry = payload;
            this.initEditor();
        });
    }

    async initEditor() {
        this.editor = new Editor({
            content: this.entry!.body.note,
            editorProps: {
                attributes: {
                    class: "prose caret-secondary-500 max-w-none border border-gray-50 hover:border-secondary-300 focus:border-secondary-500 focus:outline-none min-h-[120px] transition-colors rounded-[8px] pt-[8px] pb-[48px] px-[12px]",
                },
            },
            extensions: [
                StarterKit,
                Underline,
                Placeholder.configure({
                    placeholder: ({ node }) => {
                        if (this.entryType.value === "EMAIL") {
                            return "Voeg een e-mail toe...";
                        }
                        if (this.entryType.value === "PHONE") {
                            return "Voeg een telefoongesprek toe...";
                        }
                        return "Voeg een notitie toe...";
                    },
                }),
                Mention.configure({
                    HTMLAttributes: {
                        class: "mention",
                    },
                    renderLabel({ options, node }) {
                        return `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`;
                    },
                    suggestion,
                }),
            ],
        });
    }

    async submit(form: FormClass) {
        if (!this.entry) {
            return;
        }

        try {
            await this.patchEntry({ id: this.entry.id, body: { note: this.editor?.getHTML() ?? "" } });

            this.$toast.open({ message: "Post aangepast!", type: "success", position: "bottom-right" });

            this.handleClose();
        } catch (e) {
            this.errorResponse = this.formatErrors(e);
        } finally {
            form.reset();
        }
    }

    async handleDelete() {
        if (!this.entry) {
            return;
        }

        try {
            this.deleting = true;

            await this.deleteEntry(this.entry.id);

            this.$toast.open({ message: "Post verwijderd!", type: "success", position: "bottom-right" });

            this.deleted = true;

            this.handleClose();
        } catch (e) {
            console.log(e);

            this.errorResponse = this.formatErrors(e);
        } finally {
            this.deleting = false;
        }
    }

    handleClose() {
        this.clearErrors();

        this.show = false;

        if (!this.editor) {
            return;
        }

        this.editor.destroy();
    }
}
